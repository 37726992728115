.calendar .fc {
    td, th {
      border-style: none !important;
    }
    .fc-toolbar.fc-header-toolbar {
      margin: 0;
    }
    .fc-toolbar-title {
      display: none;
    }

    .fc-col-header-cell > .fc-scrollgrid-sync-inner {
      border: 1px;
      border-bottom: 1px;
      border-style: solid;
      border-color: lightgray;
      margin-bottom: 5px;
      border-radius: 8px;
      @media (min-width: 640px) {
        margin: 10px 10px;

      }
    }

    .fc-scrollgrid {
      border: none;
    }
    
    .fc-button-primary {
      border: none;
      border-radius: 0;
      color: lightgray;
      background: white;
    }

    .fc-button-primary:not(:disabled):active, 
    .fc-button-primary:not(:disabled).fc-button-active,
    .fc-button-primary:not(:disabled):active:focus,
    .fc-button-primary:not(:disabled).fc-button-active:focus,
    .fc-button-primary:focus,
    .fc-button-primary:hover,
    .fc-button-primary:active {
      background: white;
      color: black;
      border: none;
      box-shadow: none;
      border: none;
      background-color: none;
    }

    .fc-today-button {
      display: none;
    }
  
    /*
    .fc-icon-chevron-left:before,
    .fc-icon-chevron-right:before {
      content: "\2022";
    }
    .fc-daygrid-event-dot {
      display: none;
    }
    */

    .fc-toolbar  {
      display: inline;
      @media (min-width: 640px) {
        display: flex;
      }
    }

    .fc-daygrid-event-harness {
      padding: 0px 10px;
      max-width: 100%;
    }
    .fc-daygrid-event-harness:hover {
      max-width: unset;
      overflow: visible;
      background-color: white;
      z-index: 100;
      width: fit-content;
      padding: 0px;
      a {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .fc-day-today.fc-col-header-cell > .fc-scrollgrid-sync-inner {
      animation: background-color-animation 2s 0.08s infinite;
      transition: right 1s;
    }

    .fc-day-today > .fc-daygrid-day-frame {
      background: white;
    }

    .fc-event-time {
      color: gray;
    }

    .fc-toolbar-chunk {
      text-align: end;
    }
}