
.alice-carousel {
    &__stage {
        display: flex;
    }
    &__stage-item {
        flex: 1 0 auto;
        height: auto;
    }
    &__dots {
        margin: 0px;
    }
}

.item {
    height: 80vh;
}
