.footer {
    &__nopal-img {
        width: 157px;
    }
    &__mundo-img {
        display: inline;
        width: 15px;
    }
    button {
        display: block;
        margin-top: 5px;
        background: #f1c938;
        border-radius: 20px;
        padding: 0px 40px;
    }
    input {
        border-radius: 20px;
        padding-left: 0.5rem;
        background: #F0F0F0;
    }
}