.streaming-player {
    $self: &;
    display: flex;
    position: relative;
    cursor: pointer;
    border: 2px solid black;
    color: white;
    &:focus {
        outline:0;
    }

    &__container { 
        background-color: black;
        width: 100%;
    }

    &__icon {
        width: 45px;
        height: 45px;
        display: flex;
        svg {
            margin: auto;
            top: 1px;
            position: relative;
            display: block;
            box-sizing: border-box;
            width: 0;
            border-color: transparent transparent transparent white;
            transition: .1s all ease;
            cursor: pointer;
            border-style: solid;
            outline: 0;
            height: 24px;
            left: 2px;
            border-width: 12px 0 12px 20px;
        }
    }

    &--playing {
        svg {
            animation: playing-animation 1s 0.08s infinite;
            border-style: double;
            border-width: 0 0 0 18px;
            outline: 0;
            left: 0px;
        }
    }

    &--isOffline {
        cursor: wait;
        svg {
            border-color: transparent transparent transparent gray;
        }
    }
    

    @keyframes playing-animation {
        0% {
            opacity: 1;
        }
        30% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
}
