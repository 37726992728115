.social-network-links {
    text-align: right;
    display: flex;
    mix-blend-mode: exclusion;
    a {
        padding: 0 0.5rem;
    }
    svg {
        fill: var(--header-text-color);
    }
}
