.lista-de-lugres {
    &__descripcion {
        overflow: hidden;

        white-space: pre-wrap;
        p { 
            padding: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* start showing ellipsis when 4rd line is reached */
        }
    }
}