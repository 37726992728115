@tailwind base;
@layer base {
  .portable-text {
    font-size: 0.938rem;
    h1 {
      @apply text-6xl;
    }

    h2 {
      @apply text-5xl;
    }

    h3 {
      @apply text-4xl;
    }

    h4 {
      @apply text-3xl;
    }

    h5 {
      @apply text-2xl;
    }

    p {
      padding-bottom: 0.313rem;
    }
    strong, a {
      font-family: 'Circular Std Black';
    }
  }
}
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Circular Std Book';
  ::selection {
    background: black;
    color: white;
  }
}

@font-face {
  font-family: 'Circular Std Black';
  src: url('./assets/fonts/CircularStd/CircularStd-Black.woff2') format('woff2'),
    url('./assets/fonts/CircularStd/CircularStd-Black.woff') format('woff'),
    url('./assets/fonts/CircularStd/CircularStd-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('./assets/fonts/CircularStd/CircularStd-Book.woff2') format('woff2'),
    url('./assets/fonts/CircularStd/CircularStd-Book.woff') format('woff'),
    url('./assets/fonts/CircularStd/CircularStd-Book.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@keyframes background-color-animation {
  0% {
    background-color: rgb(196, 212, 230);
  }
  25% {
    background-color: #5fcea5;
  }
  50% {
    background-color: rgb(255, 254, 192);
  }
  75% {
    background-color: rgb(241, 208, 201);
  }
  100% {
    background-color: rgb(196, 212, 230);
  }
}

.invert-svg {
  filter: invert(1);
}
