.search-bar {
    --search-bar-height: 13px;
    border-radius: 20px;
    color: black;
    background: #F0F0F0;
    line-height: var(--search-bar-height);
    font-size: var(--search-bar-height);
    input {
        line-height: var(--search-bar-height);
        border-radius: 20px;
        background: #F0F0F0;
        &:focus {
            outline: none;
            outline-width: 0;
            box-shadow: none;
        }
    }
    button {
        &:focus {
            outline: none;
            outline-width: 0;
            box-shadow: none;
        } 
    }
}