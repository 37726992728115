.page-title {
  background-size: cover;

  h1 { 
      font-family: 'Circular Std Black';
      line-height: 48px;
      color: #0029FF;
  }

  h2 {
      font-family: 'Circular Std Black';
  }

  &__info--inverse {
    span {
      color: white;
      padding: 5px 15px;
      border-radius: 50px;
      background-color: rgba(0, 0, 0, 0.7);
      line-height: 70px;
    }
  }

  &__image-container {
    width: 75%;
    margin: auto;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
