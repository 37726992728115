.header {
    $self: &;
    top: -2px;
    &__logo {
        filter: invert(100%);
        height: 60px;
    }
    &__container {
        color: white;
        height: 85px;
        background-color: black;
        box-shadow: 0px rgba(0, 0, 0, 0.2);
    }
    &__now-playing {
        margin: 0rem 0.75rem;
        color: red;
        width: 50%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    &__logo {
        display: inline;
    }
    &.header--is-pinned {
        /*
        #{ $self }__logo {
            display: inline;
        }
        */
        #{ $self }__container {
            background-color: blue;
            box-shadow: 20px -15px 15px 25px rgba(0, 0, 0, 0.2);
        }
    }
}
