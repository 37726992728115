.menu {
    animation: background-color-animation 20s 0.08s infinite;
    transition: right 0.4s;
    animation-direction: reverse;
    a:hover {
        text-decoration: underline;
    }
    &--hidden {
        right: -100vw;
    }
    &--show {
        right: 0vw;
    }
}
